import { createStore } from 'vuex'
import getters from './getters'
import tagsView from './tagsView'
import jcPermissionAdmin from './modules/jcPermissionAdmin'
import app from './modules/app'
import jcUserAdmin from './modules/jcUserAdmin'
import dict from './modules/dict'
// 本地存储解决刷新数据小时问题
import createPersistedState from "vuex-persistedstate"

const store = createStore({
	modules: {
		app,
		tagsView,
		jcPermissionAdmin,
		jcUserAdmin,
		dict
	},
	getters,
	plugins: [
		createPersistedState({
			// 也可以是sessionstorage
			storage: window.localStorage,
			// tagsView: tagsView,
			paths: ['tagsView', 'app', 'jcPermissionAdmin', 'jcUserAdmin', 'dict']
		})
	]
})
export default store;
