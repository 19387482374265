import https from '../../utils/http'
import Layout from '@/layout/index.vue'
import ParentView from '@/components/ParentView/index.vue';
import router from "@/router";
import Cache from "@/utils/cache";
import { ElMessage, ElMessageBox } from "element-plus";
import store from "@/store";

const state: any = {
	jcAdminMenuList: [],
	jcAdminRouteMap: [],
	jcAdminMenuLevel: {},
	jcMenuShape: false,
}
const mutations = {
	ADD_MENU: (state: any, device) => {
		state.jcAdminMenuList = device
	},
	ADD_SHOW: (state: any, device: Boolean) => {
		state.jcMenuShape = device
	},
	DEL_MENU: (state: any) => {
		state.jcAdminMenuList = []
		sessionStorage.clear()
	},
	TOGGLE_DEVICE: (state: any, device: String) => {
		state.jcAdminMenuLevel = device
	},
	DEL_TOGGLE_DEVICE: (state: any) => {
		state.jcAdminMenuLevel = {}
	},
	SET_ROUTE_MAP(state, routers) {
		state.jcAdminRouteMap = routers
		// 为了防止用户刷新页面导致动态创建的路由失效，将其存储在本地中
		Cache.setStorage('jcAdminRouteMap', routers);
	},
	SET_DYNAMIC_ROUTE_MAP(state, routers) {
		let routerMaps = filterRouter(routers)
		// 最后追加404路由
		routerMaps.push({
			path: "/:catchAll(.*)*",
			component: Layout,
			redirect: "/404",
			children: [
				{
					path: '/404',
					name: '404',
					component: () => import('@/views/404.vue'),
					meta: {
						title: '找不到页面',
					}
				},
			]
		})
		// 追加路由
		// 这块是重点，如果直接使用addRoute是无效的
		routerMaps.forEach(item => {
			router.addRoute(item);
		})
	},
}
const actions = {
	levelDevice(context: any, device: String) {
		context.commit('TOGGLE_DEVICE', device)
	},
	adminMenuAdd(context: any, device: String) {
		return new Promise((resolve, reject) => {
			const jcAdminRouteMap = JSON.parse(sessionStorage.getItem('jcAdminRouteMap'));
			if (jcAdminRouteMap) {
				context.commit('SET_DYNAMIC_ROUTE_MAP', jcAdminRouteMap)
				resolve(true)
			} else {
				https.get("/passport/list-menus", {}, false).then((res: any) => {
					if (!res || res.length <= 0) {
						ElMessageBox.alert("您没有任何权限，请联系管理员", "温馨提示", {
							confirmButtonText: "知道了",
							callback: (action) => {
								store.dispatch('jcUserAdmin/logout')
							},
						});
						reject(false)
						return;
					}
					context.commit('SET_ROUTE_MAP', res)
					context.commit('SET_DYNAMIC_ROUTE_MAP', res)
					context.commit('ADD_MENU', res)
					resolve(true)
				})
			}
		})
	},
	showAdd(context: any, device: Boolean) {
		context.commit('ADD_SHOW', device)
	},
	adminMenuDel(context: any) {
		context.commit('DEL_MENU')
		context.commit('DEL_TOGGLE_DEVICE')
	}
}

// 遍历后台传来的路由字符串，转换为组件对象
const filterRouter = (routers) => {
	return routers.filter((router) => {
		// 处理 meta 属性
		router.meta = {
			title: router.name,
			icon: router.icon,
			keepAlive: !router.keepAlive,
		}
		router.hidden = !router.visible
		// 区分布局与视图文件，因为加载方式不同
		if (router.children && router.children.length > 0) {
			router.component = Layout
			if (router.parentId === 0) {
				router.component = Layout
			} else {
				router.component = ParentView
			}
		} else {
			// view
			router.component = loadView(router.component)
		}

		// 判断是否存在子路由，并递归调用自己
		if (router.children && router.children.length) {
			router.children = filterRouter(router.children)
		}
		return true
	})
}
export const loadView = (view) => { // 路由懒加载
	// console.log(`@/views/${view}.vue`)
	return () => import(`../../views/${view}.vue`)
	// return (resolve) => require([`@/views/${view}.vue`], resolve)
}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}
