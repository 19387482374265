import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-315d239e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sidebar-level"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (JSON.stringify($setup.menuItems.value) != '{}' && !$setup.menuShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_scrollbar, { "wrap-class": "scrollbar-wrapper" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_menu, {
              class: "sidebar-el-menu",
              "default-active": $setup.onRoutes,
              "text-color": "#2D2E2F",
              "active-text-color": "#fff",
              "unique-opened": "",
              router: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuItems.value.children, (item) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (item.children && item.children.length > 0)
                      ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                          "popper-append-to-body": true,
                          index: item.id + '',
                          key: item.path
                        }, {
                          title: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(item.name), 1)
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem) => {
                              return (_openBlock(), _createElementBlock(_Fragment, null, [
                                (subItem.children && subItem.children.length > 0)
                                  ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                                      "popper-append-to-body": true,
                                      index: subItem.path,
                                      key: subItem.path
                                    }, {
                                      name: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subItem.name), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subItem.children, (threeItem, i) => {
                                          return (_openBlock(), _createBlock(_component_el_menu_item, {
                                            key: i,
                                            index: threeItem.path,
                                            onClick: ($event: any) => ($setup.menuTouch(threeItem))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(threeItem.name), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["index", "onClick"]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1032, ["index"]))
                                  : (_openBlock(), _createBlock(_component_el_menu_item, {
                                      index: subItem.path,
                                      key: subItem.path,
                                      class: "level-item",
                                      onClick: ($event: any) => ($setup.menuTouch(subItem))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subItem.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["index", "onClick"]))
                              ], 64))
                            }), 256))
                          ]),
                          _: 2
                        }, 1032, ["index"]))
                      : (_openBlock(), _createBlock(_component_el_menu_item, {
                          index: item.path,
                          key: item.path,
                          onClick: ($event: any) => ($setup.menuTouch(item))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["index", "onClick"]))
                  ], 64))
                }), 256))
              ]),
              _: 1
            }, 8, ["default-active"])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}