
// 当前前台是否为开发模式
const isDev = process.env.NODE_ENV === 'development'
let siteUrl = isDev ? 'http://192.168.0.154:8086' : 'https://mtest.bjftyx.net/suppliersys'
let jcAdminUrl = isDev ? 'http://192.168.0.154' : 'https://mtest.bjftyx.net/suppliersys'
let jcAdminApiUrl = isDev ? 'http://192.168.0.154:8088' : 'https://mtest.bjftyx.net/supplier-admin-api'
let jcSupplierApiUrl = isDev ? 'http://192.168.0.154:8086' : 'https://mtest.bjftyx.net/supplier-api'
switch (process.env.VUE_APP_APP_HOST) {
	case 'development'://测试
		siteUrl = 'https://mtest.bjftyx.net/suppliersys'
		jcAdminUrl = 'https://mtest.bjftyx.net/suppliersys'
		jcAdminApiUrl = 'https://mtest.bjftyx.net/supplier-admin-api'
		jcSupplierApiUrl = 'https://mtest.bjftyx.net/supplier-api'
		break
	case 'production'://正式
		siteUrl = 'https://jc.bjftyx.net/suppliersys'
		jcAdminUrl = 'https://jc.bjftyx.net/suppliersys'
		jcAdminApiUrl = 'https://jc.bjftyx.net/supplier-admin-api'
		jcSupplierApiUrl = 'https://jc.bjftyx.net/supplier-api'
		break
}
export default {
	pageSize: 10,
	siteUrl: siteUrl,
	jcAdminUrl: jcAdminUrl,
	jcAdminApiUrl: jcAdminApiUrl,
	jcSupplierApiUrl: jcSupplierApiUrl,//文件上传
	isDev: true,
}
