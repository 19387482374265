import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
import Cache from '../utils/cache'
import store from '@/store'
import Config from "@/config/config";

const routes: Array<RouteRecordRaw> = [
    {
        name: 'index',
        path: '/',
        redirect: '/home/dashboard',
        component: Layout,
        children: [{
            name: 'home_dashboard',
            path: '/home/dashboard',
            component: () => import('@/views/home/dashboard.vue'),
            meta: {
                title: '本家平台管理中心',
                icon: 's-home',
                color: '#fd9d21',
                keepAlive: true
            }
        },
            /*{
                name: 'dashboard',
                path: '/home/dashboard',
                component: () => import('@/views/home/dashboard.vue'),
                meta: {
                    title: '控制台',
                    color: '#fd9d21',
                    keepAlive: false
                }
            },*/
        ]
    },
    {
        path: "/system",
        component: Layout,
        children: [
            {
                path: '/system/dict/data/:dictId',
                name: 'dict-data',
                component: () => import('@/views/systemAdmin/dict/data.vue'),
                meta: {
                    title: '字典数据',
                }
            },
        ]
    },
    {
        path: '/login.html',
        name: 'login',
        component: () => import('@/views/passport/login.vue'),
        meta: {
            title: '登录',
        }
    },
    {
        path: "/financeAdmin",
        component: Layout,
        children: [

            {
                path: '/financeAdmin/merchantOrderBillDetailList',
                name: 'merchantOrderBillDetailList',
                component: () => import('@/views/financeAdmin/merchantOrderBillDetailList.vue'),
                meta: {
                    title: '结算账单明细',
                }
            },
            {
                path: '/financeAdmin/merchantAccount',
                name: 'merchantAccount',
                component: () => import('@/views/financeAdmin/merchantAccount.vue'),
                meta: {
                    title: '账户明细',
                }
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory("/"),
    routes
})

const whiteList = ['/login.html']; //白名单

router.beforeEach(async (to, from, next) => {
    let accessToken = Cache.getCookie(Cache.bjjcKeyToken)
    //当前路由
    //判断标题
    document.title = to.meta.title ? String(to.meta.title) : '商家管理后台';
    const toPath: string = to.path;
    if (accessToken) {
        let user = Cache.getStorage(Cache.bjjckeyUser);
        if (!store.getters.jcDictDatas || Object.keys(store.getters.jcDictDatas).length === 0) {
            await store.dispatch('dict/loadDictDatas')
        }
        if (toPath === '/login.html') {
            next({ path: '/' })
        } else if (!user) {
            // 取不到用户就退出登录
            await store.dispatch("jcUserAdmin/logout");
        } else if (store.getters.jcAdminMenuList.length <= 0 || !router.hasRoute(to.name)) {
            await store.dispatch('jcPermissionAdmin/adminMenuAdd').then(accessedRoutes => {
                //  accessedRoutes.forEach(async (route: RouteRecordRaw) => {
                //     const routeName: any = route.name;
                //     if (!router.hasRoute(routeName)) {
                //         router.addRoute(route);
                //     }
                // })
                next({
                    ...to,
                    replace: true
                })
                return
            })
        } else {
            next()
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            // window.location.href="/login.html"
            next(`/login.html`)
        }
    }
})

export default router
