const tagsView = {
	state: {
		jcVisitedViews: [],
	},
	mutations: {
		ADD_VISITED_VIEWS: (state, view) => {
			if (state.jcVisitedViews.some(v => v.path == view.path)) return;
			state.jcVisitedViews.push(Object.assign({}, view, {
				name: view.name || 'no-name',
				path: view.path || '/'
			}))
		},
		DEL_VISITED_VIEWS: (state, view) => {
			for (const [i, v] of state.jcVisitedViews.entries()) {
				if (v.path === view.path) {
					state.jcVisitedViews.splice(i, 1)
					break
				}
			}
		},
		DEL_OTHERS_VIEWS: (state, view) => {
			for (const [i, v] of state.jcVisitedViews.entries()) {
				if (v.path === view.value.path) {
					state.jcVisitedViews = state.jcVisitedViews.slice(i, i + 1)
					break
				}
			}
		},
		DEL_ALL_VIEWS: (state) => {
			state.jcVisitedViews = []
			sessionStorage.clear()
		}
	},
	actions: {
		addVisitedViews({ commit }, view) {
			commit('ADD_VISITED_VIEWS', view)
		},
		delVisitedViews({ commit, state }, view) {
			return new Promise((resolve) => {
				commit('DEL_VISITED_VIEWS', view)
				resolve([...state.visitedViews])
			})
		},
		delOthersViews({ commit, state }, view) {
			return new Promise((resolve) => {
				commit('DEL_OTHERS_VIEWS', view)
				resolve([...state.visitedViews])
			})
		},
		delAllViews({ commit, state }) {
			return new Promise((resolve) => {
				commit('DEL_ALL_VIEWS')
				resolve([...state.visitedViews])
			})
		}
	}
}

export default tagsView
